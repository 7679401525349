import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { BottomNavbarComponent } from './components/bottom-navbar/bottom-navbar.component';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { FilterByPipe } from './pipes/filterBy/filter-by.pipe';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { SortByPipe } from './pipes/sortBy/sort-by.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FilterLocationsPipe } from './pipes/filterBkp/filter-locations.pipe';
import { FilterTagsByCategoryPipe } from './pipes/filterTagsByCategory/filter-tags-by-category.pipe';
import { ClickOutsideDirectiveDirective } from './directives/clickOutsdieDirective/click-outside-directive.directive';

@NgModule({
  declarations: [
    SvgIconComponent,
    BottomNavbarComponent,
    NotFoundComponent,
    FilterPipe,
    FilterByPipe,
    TruncatePipe,
    DatePickerComponent,
    DropDownComponent,
    SortByPipe,
    FilterLocationsPipe,
    FilterTagsByCategoryPipe,
    ClickOutsideDirectiveDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    SvgIconComponent,
    BottomNavbarComponent,
    NotFoundComponent,
    FilterPipe,
    FilterByPipe,
    SortByPipe,
    TruncatePipe,
    DatePickerComponent,
    DropDownComponent,
    FilterLocationsPipe,
    ClickOutsideDirectiveDirective
  ]
})
export class SharedModule { }
