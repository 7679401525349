export const environment = {
production: true,
  appUrl: "https://bavox.ch/",
  apiUrl: "https://dev.bavox.ch/api/v1",
  websocket: 'wss://dev.bavox.ch/',
  websocketpath: ''
};

// export const environment = {
// production: true,
//   appUrl: "https://bavox.ch/",
//   apiUrl: "http://192.168.0.117:4500/api/v1",
//   websocket: 'ws://192.168.0.117:4500/',
//   websocketpath: ''
// };