<div class="chat chat-start !gap-0">
    <div class="chat-image avatar">
        <div class="w-[50px]">
            <img class="!object-contain transform -scale-x-100" src="../../../../assets/images/logo/bavox-mascot-thinking.svg" />
        </div>
    </div>
    <div class="flex items-end gap-1">
      <div class="chat-bubble !min-h-0 !bg-error-color-500 !bg-opacity-25 rounded-md before:!content-none">
        <h1 class="text-error-color-500 font-medium text-base text-left">
            Report
        </h1>
        <div class="bav-info text-left">
            {{message.data.text}}
        </div>
      </div>
      <div class="chat-footer opacity-50 break-keep">
        {{message.date | date: "HH:mm"}}
      </div>
    </div>
  </div>
