import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { icons, navIcons } from 'src/assets/images/icons';

import { AbilityService } from '@casl/angular';
import { Observable, Subscription, filter, map } from 'rxjs';
import { AppAbility } from 'src/app/shared/services/AppAbility';
import { environment } from 'src/environments/environment';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Platform } from '@angular/cdk/platform';
import { NetworkStatusService } from 'src/app/core/services/networkStatusService/network-status.service';

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss']
})
export class BottomNavbarComponent implements OnInit, OnDestroy {
  public appUrl: string = environment.appUrl;
  public home: string = navIcons.home;
  public copyToClipBoard: string = navIcons.copyToClipboard;
  public world: string = navIcons.world;
  public building: string = navIcons.building;
  public chat: string = navIcons.chat;
  public addressBook: string = navIcons.addressBookIcon;

  public actionIcon: string = icons.actionIcon;
  public addShortcutIcon: string = icons.addShortcutIcon;
  public exitIcon: string = icons.exitIcon;

  public baseRoute: boolean = false;
  isOnline!: boolean;
  modalVersion!: boolean;
  modalPwaEvent: any;
  modalPwaPlatform: string|undefined;
  installApp: boolean | string = true;
  platformPrompt: boolean = false;
  appBar: boolean = false;

  public networkOnline: boolean | undefined;

  public projectId: string | null = null;
  public journalId: string | null = null;
  public journalType: string | null = null;
  public sharedLinkId: string | null = null;

  private onlineStatusSubscription!: Subscription;
  readonly ability$: Observable<AppAbility>;

  constructor(
    private networkStatusService: NetworkStatusService,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(AbilityService) private abilityService: AbilityService<AppAbility>) { 
      this.ability$ = abilityService.ability$;
      this.isOnline = false;
      this.modalVersion = false;
    }

  ngOnInit() {
    // const routeSegments = this.activatedRoute.snapshot.url;
    // if (routeSegments.length > 0) {
    //   this.baseRoute = routeSegments[0].path;
    // } else {
    //   this.baseRoute = null;
    // }

    this.baseRoute = this.router.url.includes('tasks');

    this.activatedRoute.params.subscribe(params => {
      const { projectId, journalId, journalType, sharedLinkId } = params;
      this.projectId = projectId;
      this.journalId = journalId;
      this.journalType  = journalType;
      this.sharedLinkId  = sharedLinkId;
    })

    this.networkStatusService.connected$.subscribe(status => {
      this.networkOnline = status;
    })

    this.updateOnlineStatus();
    this.onlineStatusSubscription = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => {
        console.info(`currentVersion=[${evt.currentVersion}] | latestVersion=[${evt.latestVersion}]`);
        this.modalVersion = true;
      })
    ).subscribe();
    this.loadModalPwa();
  }

  ngOnDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    this.onlineStatusSubscription?.unsubscribe();
  }

  appBarToggle(): void {
    this.installApp = !this.installApp;
    localStorage.setItem('installApp', this.installApp.toString());
  }

  platformPromptToggle(): void {
    this.platformPrompt = !this.platformPrompt;
  }

  private updateOnlineStatus = (): void => {
    this.isOnline = navigator.onLine;
    // console.info(`isOnline=[${this.isOnline}]`);
  };

  public updateVersion(): void {
    this.modalVersion = false;
    window.location.reload();
  }

  public closeVersion(): void {
    this.modalVersion = false;
  }
  private loadModalPwa(): void {
    const installApp = localStorage.getItem('installApp');
    if(installApp === 'false'){
      this.installApp = false;
    }else{
      this.installApp = true;
    }
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'ANDROID';
      });
    }

    if (this.platform.IOS && this.platform.SAFARI) {
      const isInStandaloneMode = ('standalone' in window.navigator) && ((<any>window.navigator)['standalone']);
      if (!isInStandaloneMode) {
        this.modalPwaPlatform = 'IOS';
      }
    }
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }

  public closePwa(): void {
    // localStorage.setItem('installApp', 'false');
    this.platformPromptToggle()
  }
}
