<!-- <div class="pa-message-content pa-message-content-text" [innerHTML]="message.data.text | emoji"></div> -->

<div *ngIf="message.sender === 'server'" class="chat chat-start !gap-0">
  <div class="chat-image avatar">
    <div class="w-[50px]">
      <img class="!object-contain transform -scale-x-100" src="../../../../assets/images/logo/bavox-mascot.svg" />
    </div>
  </div>
  <div class="flex items-end gap-1">
    <div class="chat-bubble !min-h-0 !bg-white-shades-200 text-secondary-color-500 rounded-md before:!content-none md:!min-w-fit">
      <p class="text-base font-normal break-words text-pretty text-left" [innerHTML]="message.data.text | emoji"></p>
      <p class="italic text-left text-sm opacity-50" [innerHTML]="message.data.support | emoji"></p>
    </div>
    <div class=" break-keep">
      <p class="text-nowrap">{{message.date | date: 'HH:mm'}}</p>
    </div>
  </div>
</div>

<div *ngIf="message.sender === 'client'" class="chat chat-end !gap-0">
  <div class="flex items-end flex-row-reverse gap-1">
    <div class="chat-bubble !min-h-0 text-left bg-main-color-100 rounded-md before:!content-none" [innerHTML]="message.data.text | emoji">
    </div>
    <div class="chat-footer opacity-50 break-keep">
      <p class="text-nowrap">{{message.date | date: 'HH:mm'}}</p>
    </div>
  </div>
</div>

