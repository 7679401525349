import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DeletedMessagesHolder } from './deletedMessagesHolder';
import { MessageType } from '../../../../libs/botato-angular-lib/botato-chat-message/message-type/message-type';
import { MessageViewModel } from '../../../../libs/botato-angular-lib/viewmodel/MessageViewModel';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { icons } from 'src/assets/images/icons';
import { TasksService } from 'src/app/tasks/services/tasksService/tasks.service';
import { Location } from '@angular/common';
import { NavigationService } from 'src/app/shared/services/navigationService/navigation.service';
import { filter } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import flatpickr from 'flatpickr';
import { DatabaseService } from 'src/app/core/services/databaseService/database.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements MessageType, OnInit {
  public trashIcon: string = icons.trashCanIcon;
  public cameraIcon: string = icons.cameraIcon;
  public showMoreIcon: string = icons.verticalShowMoreIcon;
  public imageIcon: string = icons.imageIcon;
  public exitIcon: string = icons.exitIcon;
  public calendarIcon: string = icons.calendarIcon;

  private updateAlert: string = 'Updated successfully';
  private errorAlert: string = 'Something went wrong';

  public isLoading: boolean = false;
  public isAddDueDateModalOpen: boolean = false;

  private token: string | null = null;
  public taskId: string | null = null;
  public projectId: string | null = null;
  public sharedLinkId: string | null = null;
  public journalType: string | null = null;
  public journalId: string | null = null;

  public static TYPE = 'NoteItem';
  @ViewChild('dateInput', { static: false }) dateInput!: ElementRef; // Reference to the input element
  flatpickrInstance: any; // Flatpickr instance

	@ViewChild('cameraInput') cameraInput!: ElementRef;
  public isBrowser: boolean = false;

  @Input()
  public message!: MessageViewModel;

  public dueDateTask = new FormGroup({
    deadlineDate: new FormControl(),
  });

  constructor(
    private authService: AuthService,
		private dbService: DatabaseService,
    private api: TasksService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private navigationService: NavigationService,
    public router: Router,
    private route: ActivatedRoute,
		public platform: Platform
  ) {
		if (this.platform.ANDROID || this.platform.IOS) {
			this.isBrowser = false;
		} else if (this.platform.isBrowser) {
			this.isBrowser = true;
		};

	}

  ngOnInit() {
    this.token = this.authService.getToken();
    this.route.params.subscribe((params) => {
      const { projectId, sharedLinkId, mode, journalId } = params;
      this.projectId = projectId;
      this.sharedLinkId = sharedLinkId;
      this.journalType = mode;
      this.journalId = journalId;
    });

    this.translate
      .get(['alert.update', 'alert.error'])
      .subscribe((translations: { [key: string]: string }) => {
        this.updateAlert = translations['alert.update'];
        this.errorAlert = translations['alert.error'];
      });
  }

  ngAfterViewInit(): void {
    this.initializeFlatpickr();
  }

  ngOnDestroy(): void {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy();
    }
  }

  initializeFlatpickr(): void {
    this.flatpickrInstance = flatpickr(this.dateInput.nativeElement, {
      dateFormat: 'dd-mm-YYYY', // Format for value binding (e.g., "2024-12-20")
      altInput: true, // Display a human-readable format in the input
      altFormat: 'd/m/Y', // Human-readable format (e.g., "December 20, 2024")
      allowInput: false, // Allow manual input of dates
      minDate: new Date(), // Disable past dates
      locale: 'de',
      inline: true,
      defaultDate: new Date(),
      disableMobile: true, // Force non-native Flatpickr on mobile devices
      onChange: (selectedDates) => {
        this.dueDateTask.controls['deadlineDate'].setValue(selectedDates[0]); // Update reactive form control value
      },
      onClose: () => {
        const control = this.dueDateTask.controls['deadlineDate'];
        if (control) {
          control.markAsDirty();
          control.markAsTouched();
        }
      },
    });
  }

  public isDeleted(): boolean {
    return DeletedMessagesHolder.isDeleted(this.getMessageId());
  }

  public async addPicture(): Promise<void> {
    if (!!this.sharedLinkId) {
      await this.router.navigate([
        'camera',
        'shared',
        this.sharedLinkId,
        this.route.snapshot.params['projectId'],
        this.route.snapshot.params['mode'],
        this.route.snapshot.params['journalId'],
        'chat',
        'note',
        this.getMessageId(),
      ]);
    } else {
      await this.router.navigate([
        'camera',
        this.route.snapshot.params['projectId'],
        this.route.snapshot.params['mode'],
        this.route.snapshot.params['journalId'],
        'chat',
        'note',
        this.getMessageId(),
      ]);
    }
  }

  public async deleteNote(): Promise<void> {
    const token = localStorage.getItem('token');
    DeletedMessagesHolder.delete(this.getMessageId());
    this.api.deleteTask(token, this.getMessageId()).subscribe();
  }

  private getMessageId(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    return this.message.data.note.id;
  }

  public navigateToGallery(taskId: string) {
    const navigateTo = `/tasks/${this.projectId}/${this.journalId}/${this.journalType}/task-details/${taskId}/photo-gallery`;
    this.navigationService.setOriginForPage('originUrl', this.router.url);
    this.router.navigate([navigateTo]);
  }

  onDueDateSubmit() {
    const now = new Date();
    const deadlineDate = this.dueDateTask.controls['deadlineDate'].value!;
    const date = new Date(deadlineDate);
    date.setHours(
      now.getHours(),
      now.getMinutes() + 1,
      now.getSeconds(),
      now.getMilliseconds()
    );

    const body = {
      deadlineDate: date,
    };

    if (this.dueDateTask.valid) {
      this.isLoading = true;
      this.api.updateTask(this.token, this.taskId, body).subscribe({
        next: (res) => {
          if (res) {
            this.isLoading = false;
            this.toastr.success(this.updateAlert, '', {
              positionClass: 'toast-top-center',
            });
            this.isAddDueDateModalOpen = false;
          }
        },
        error: (err) => {
          if (err) {
            this.isLoading = false;
            this.toastr.error(this.errorAlert, '', {
              positionClass: 'toast-top-center',
            });
            this.isAddDueDateModalOpen = false;
          }
        },
      });
    }
  }

	onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.blobToBase64(file).then(res => {
        this.editImage(res);
      });   
    }
  };

  async editImage(file: any) {
    await this.dbService.addEditUploadedImages({ data: file })
      .then(() => this.addPicture());
  };

	blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

	openCameraDialog() {
    this.cameraInput.nativeElement.click();
  };

  toggleAddDueModal(taskId: string | null) {
    this.isAddDueDateModalOpen = !this.isAddDueDateModalOpen;
    this.taskId = taskId;
  }
}
