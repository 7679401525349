import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private envUrl = environment.apiUrl;
  public redirectUrl: string | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {}

  // new services
  login({email, password}:{email: string, password: string}): Observable<any> {
    return this.http.post(`${this.envUrl}/users/login`,
      { email, password },
      { observe: 'response' }
    )
    .pipe(
      map((res:any) => res),
      catchError((error: any): Observable<any> => throwError(error))
    );
  }

  register(inputValue:any): Observable<any> {
    const {name, sex, firstName, lastName, email, password, phone} = inputValue;
    const createUser = {
      organization: {
        name
      },
      sex,
      firstName,
      lastName,
      email,
      password,
      phone
    }
    return this.http.post(`${this.envUrl}/users/register`, createUser)
    .pipe(
      map((res:any) => res.data),
      catchError((error: any): Observable<any> => throwError(error))
    );
  }

  logout() {
    this.clearAuthData();
    this.router.navigate(['/auth/login']);
  }

  clearAuthData() {
    localStorage.clear();
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${this.envUrl}/users/forgot-password`, { email }, {
      headers: {
        'ngrok-skip-browser-warning': '555'
      }
    })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => error)
      );
  }

  changePassword(token: string | null, changePswBody: any): Observable<any> {
    return this.http.post(`${this.envUrl}/users/change-password`, changePswBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        }
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  resetPassword(token: string | null, resetPswBody: any): Observable<any> {
    return this.http.post(`${this.envUrl}/users/reset-password`, {token, ...resetPswBody}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }

  deleteUser(token:string | null, userId:string | null, assignToId?: string | null): Observable<any> {
    const options: {
      headers: HttpHeaders;
      params?: HttpParams;
    }  = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "ngrok-skip-browser-warning": '555'
      }),
    };
    
    if(!!assignToId) {
      options.params = new HttpParams().set('assignToId', assignToId)
    }

    return this.http.delete(`${this.envUrl}/users/${userId}`, options)
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => error)
      );
  }

  confirmEmail(token: string | null): Observable<any> {
    return this.http.post(`${this.envUrl}/users/confirm-email`, {token}, {
      headers: {
        'ngrok-skip-browser-warning': '555',
      },
      observe: 'response',
    })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  resendEmailConfirm(token: string | null): Observable<any> {    
    return this.http.post(`${this.envUrl}/users/resend-confirm-email`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555',
      },
      observe: 'response',
    })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  isUserActive() {
    const activeAccount = JSON.parse(localStorage.getItem('activeUser')!);
    if(activeAccount === undefined || activeAccount === null){
      return true;
    }
    return activeAccount;
  }

  getLicensePeriod() {
    return localStorage.getItem('licensePeriod');
  }
  
  getUserId() {
    const isAuthenticated = localStorage.getItem('token') || '';
    if(isAuthenticated){
      const decoded: any = jwtDecode(isAuthenticated);
      return decoded.id;
    }
    return null;
  }

  getOrganizationId() {
    return localStorage.getItem('organizationId');
  }
  
  getUserRole() {
    const isAuthenticated = localStorage.getItem('token') || '';
    if(isAuthenticated){
      const decoded: any = jwtDecode(isAuthenticated);
      return decoded.role
    }
    return null;
  };

  getUserEmail() {
    const isAuthenticated = localStorage.getItem('token') || '';
    if(isAuthenticated){
      const decoded: any = jwtDecode(isAuthenticated);
      return decoded.email;
    }
    return null;
  }

  getToken() {
    return localStorage.getItem('token');
  };

  getCookies() {
    const cookieValue = this.cookieService.get('onBoarding');
    if (cookieValue) return false;
    return true;
  }

}
