<div class="form-control">
  <label *ngIf="label" class="label pl-4 py-[2px]">
    <span class="label-text text-white-shades-400">{{ label }}</span>
  </label>
  <div class="input-wrapper">
    <input 
      #dateInput
      type="text" 
      [placeholder]="placeholder && !control.value ? placeholder : ''" 
      class="input-icon-right" 
      [ngClass]="{'is-invalid': control.invalid && (control.dirty || control.touched)}"
    />
    <button (click)="onClearDate()" *ngIf="control.value" class="btn btn-circle btn-link btn-xs input-icon right">
      <app-svg-icon [src]="iconPack.exitIcon" [width]="'24px'" [height]="'24px'" [stroke]="'black'" [fill]="'black'"></app-svg-icon>
    </button>
    <p
      class="input-error-message"
      *ngIf="requiredText && (control.dirty || control.touched) && control.errors?.['required']"
    >
      {{ requiredText }}
    </p>
  </div>
</div>
