<nav *ngIf="ability$ | async as ability" class="fixed w-full mx-auto lg:w-[1008px] md:w-[900px] xs:w-[430px] z-10 bottom-0 bg-white">
  <div *ngIf="ability.can('read', 'Navigation')">

    <div *ngIf="(modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS') && installApp" class="flex items-center justify-between gap-3 h-full shadow-[0px_-3px_51px_-16px_rgba(0,0,0,0.15)] rounded-t-3xl px-4 py-3">
      <div class="flex w-full items-center gap-2">
        <img src="../../../../assets/images/logo/logo-blue.svg" class="w-[24px]" alt="logo">
        <h4 class="font-semibold text-sm max-w-screen-xs">
          Bavox zum Startbildschirm hinzufügen
        </h4>
      </div>
      <button class="btn btn-primary xs:btn-sm md:btn-sm" (click)="platformPromptToggle()">Hinzufügen</button>
      <button class="btn btn-circle btn-link btn-sm" (click)="appBarToggle()">
        <app-svg-icon
          [src]="exitIcon"
          width="26px"
          height="26px"
          stroke="black"
        />
      </button>
    </div>

    <div class="flex items-center justify-around shadow-[0px_-3px_51px_-16px_rgba(0,0,0,0.15)] h-[75px]" [ngClass]="{'rounded-t-3xl': !appBar}">
      
      <span class="md:tooltip md:tooltip-primary tooltip-top before:!text-white" data-tip="Startseite">
        <button
          class="btn btn-circle btn-link"
          #rlaHome="routerLinkActive"
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:true}"
        >
          <app-svg-icon
            [src]="home"
            [stroke]="rlaHome.isActive ? '#22A8F0' : '#ACACAC'"
            [width]="'32px'"
            [height]="'32px'"
          ></app-svg-icon>
        </button>
      </span>
      
      <span class="md:tooltip md:tooltip-primary tooltip-top before:!text-white" data-tip="Alle Projekte">
        <button
          class="btn btn-circle btn-link"
          #rlaProjects="routerLinkActive"
          routerLink="/projects"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:false}"
        >
          <app-svg-icon
            [src]="building"
            [stroke]="rlaProjects.isActive ? '#22A8F0' : '#ACACAC'"
            [width]="'28px'"
            [height]="'28px'"
            classes=""
          ></app-svg-icon>
        </button>
      </span>

      <span *ngIf="baseRoute && projectId && journalId" class="md:tooltip md:tooltip-primary tooltip-top before:!text-white flex justify-center items-center" data-tip="Chatbot starten">
        <div
          *ngIf="projectId && journalId"
          class="p-[2px] border-[2px] border-secondary-color-500 rounded-full"
          [ngClass]="{
            'border-opacity-10': !(!!networkOnline)
          }"
        >
          <button
            [disabled]="!(!!networkOnline)"
            class="btn btn-circle btn-secondary w-[52px] h-[52px]"
            #rlaProjects="routerLinkActive"
            [routerLink]="!!sharedLinkId ?
              ['/chat', 'shared', sharedLinkId, projectId, journalType, journalId] :
              ['/chat', projectId, journalType, journalId]
            "
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:false}"
          >
            <app-svg-icon
              [src]="chat"
              [stroke]="'#F9F9F9'"
              strokeWidth="2px"
              [width]="'32px'"
              [height]="'32px'"
              classes=""
            ></app-svg-icon>
        </button>
        </div>
      </span>
      
      <span class="md:tooltip md:tooltip-primary tooltip-top before:!text-white" data-tip="Aufgaben">
        <button
          class="btn btn-circle btn-link"
          #rlaTasks="routerLinkActive"
          routerLink="/tasks"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:false}"
        >
          <app-svg-icon
            [src]="copyToClipBoard"
            [stroke]="rlaTasks.isActive ? '#22A8F0' : '#ACACAC'"
            [width]="'32px'"
            [height]="'32px'"
          ></app-svg-icon>
        </button>
      </span>

      <span class="md:tooltip md:tooltip-primary tooltip-top before:!text-white" data-tip="Organisationsprofil">
        <button
          class="btn btn-circle btn-link"
          #rlaOrganization="routerLinkActive"
          routerLink="/organization"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:false}"
        >
          <app-svg-icon
            [src]="world"
            [stroke]="rlaOrganization.isActive ? '#22A8F0' : '#ACACAC'"
            [width]="'28px'"
            [height]="'28px'"
          ></app-svg-icon>
        </button>
      </span>
    </div>

  </div>
  <div *ngIf="ability.cannot('read', 'Navigation') && sharedLinkId" class="flex items-center justify-between gap-1 h-fit shadow-[0px_-3px_51px_-16px_rgba(0,0,0,0.15)] rounded-t-3xl px-2 py-3">
    <div class="flex w-full items-center gap-1">
      <img src="../../../../assets/images/logo/logo-blue.svg" class="w-[24px]" alt="logo">
      <!-- <h1 class="font-bold ">BAVOX</h1> -->
      <h4 class="font-normal md:font-semibold text-xs md:text-sm max-w-screen-xs break-all">
        Du interessierst dich für das intelligente Baumanagement-Tool von Bavox, möchtest aber gern noch mehr darüber erfahren?
      </h4>
    </div>
    <a class="btn btn-primary btn-xs md:btn-sm" target="_blank" rel="noopener noreferrer" [href]="appUrl">Webseite</a>
  </div>
</nav>

<!-- progresive web app pop ups -->
<input type="checkbox" id="my_modal_7" class="modal-toggle" [checked]="(modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS') && platformPrompt" />
<div class="modal" role="dialog">
  <div class="modal-box max-w-screen-xs">

    <div class="flex flex-col items-center justify-center gap-4 text-center text-secondary-color-500 font-bold text-xl mb-3">
      <img draggable="false" class="w-[50px]" src="../../../../assets/images/logo/logo-blue.svg" alt="logo">
    </div>

    <!-- Android -->
    <div *ngIf="modalPwaPlatform === 'ANDROID'">
      Fügen Sie diese WEB-App zum Startbildschirm hinzu
    </div>

    <!-- iOS with Safari -->
    <div *ngIf="modalPwaPlatform === 'IOS'" class="flex flex-col gap-3">
      <p>Um diese WEB-App auf Ihrem Gerät zu installieren, tippen Sie auf die Schaltfläche „Menü“.</p>
      <app-svg-icon
        [src]="actionIcon"
        height="32px"
        width="32px"
        fill="black"
      />
      <p>und dann auf die Schaltfläche „Zum Startbildschirm hinzufügen“.</p>
        
      <app-svg-icon
        [src]="addShortcutIcon"
        height="32px"
        width="32px"
        fill="black"
      />
    </div>

    <div class="modal-action">
      <label for="my_modal_7" (click)="closePwa()" class="btn btn-link btn-link-primary">{{'project-overview.close' | translate}}</label>
      <button class="btn btn-primary" (click)="addToHomeScreen()" *ngIf="modalPwaPlatform === 'ANDROID'">App installieren</button>
    </div>

  </div>
  <label class="modal-backdrop" (click)="closePwa()" for="my_modal_7">{{'project-overview.close' | translate}}</label>
</div>
