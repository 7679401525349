import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private originPage: { [key: string]: any } = {};
  private localStorageKey = 'originPages';

  constructor() {
    this.loadOriginPagesFromLocalStorage(); // Load saved state on initialization
  }

  // Set the origin URL for a specific page and save to localStorage
  setOriginForPage(filterKey: string, state: any): void {
    this.originPage[filterKey] = state;
    this.saveOriginPagesToLocalStorage();
  }

  // Retrieve the origin URL for a specific page
  getOriginForPage(filterKey: string): any {
    return this.originPage[filterKey] || null;
  }

  // Clear the origin URL for a specific page and update localStorage
  clearOriginForPage(filterKey: string): void {
    delete this.originPage[filterKey];
    this.saveOriginPagesToLocalStorage();
  }

  // Save the current origin pages to localStorage
  private saveOriginPagesToLocalStorage(): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.originPage));
  }

  // Load origin pages from localStorage
  private loadOriginPagesFromLocalStorage(): void {
    const storedData = localStorage.getItem(this.localStorageKey);
    if (storedData) {
      this.originPage = JSON.parse(storedData);
    }
  }
}