<div class="bav-main-container bg-[#1C1C1C] relative">
	<!-- <bav-header [backButtonPath]="backButtonPath">
        <div title class="bav-header-title">Bild hinzufügen</div>
		<button class="bav-button-submit" actionbuttons (click)="saveCanva()" *ngIf="isEditingPicture()">
			<i class="bav-button-submit-icon">done</i>
		</button>
    </bav-header> -->

		<div *ngIf="isTakingPicture()" class="absolute top-4 left-4 z-30">
			<button (click)="goBackToPrevPage()" class="btn btn-circle btn-link btn-sm">
				<app-svg-icon
					[src]="exitIcon"
					width="32px"
					height="32px"
					stroke="white"
					strokeWidth="2px"
				/>
			</button>
		</div>
    <div class="bav-container">
			<div class="bav-camera">
				<div style="text-align:center; display: flex; align-items: center;" display *ngIf="isTakingPicture()">
					<div>
						<webcam
							[trigger]="triggerObservable"
							[height]="screenHeight" 
							[width]="screenWidth"
							(imageCapture)="handleImage($event)" 
							*ngIf="showWebcam"
							[allowCameraSwitch]="allowCameraSwitch" 
							[switchCamera]="nextWebcamObservable" 
							[videoOptions]="videoOptions" 
							(cameraSwitched)="cameraWasSwitched($event)"
							(initError)="handleInitError($event)">
						</webcam>
					</div>
				</div>
				<!-- <div *ngIf="editingPicture" class="bav-canvas-background"><img [src]="pictureUrl"></div> -->
				<canvas #canvas class="bav-canvas" [hidden]="!editingPicture"></canvas>
			</div>
			<div class="bav-button">
				<div class="h-full py-10 flex justify-center items-center" *ngIf="!isEditingPicture()">
					<button (click)="triggerSnapshot()" *ngIf="isTakingPicture()">
						<div class="p-[4px] border-2 rounded-full">
							<div class="w-16 h-16 bg-white-shades-300 rounded-full"></div>
						</div>
					</button>
				</div>
				<div class="flex gap-4 px-7 py-5" *ngIf="isEditingPicture()">
					<button class="bav-action" (click)="undoDrawing()" *ngIf="isEditingPicture()">
						<app-svg-icon [src]="undoIcon" width="24px" height="24px" stroke="white" strokeWidth="2px"/>
						<!-- <i class="bav-redo-icon">undo</i> -->
					</button>
					<button class="bav-action" (click)="redoDrawing()" *ngIf="isEditingPicture()">
						<app-svg-icon [src]="redoIcon" width="24px" height="24px" stroke="white" strokeWidth="2px"/>
						<!-- <i class="bav-redo-icon">redo</i> -->
					</button>
				</div>
				<div class="flex gap-4 px-7 py-5" *ngIf="isEditingPicture()">
					<div class="dropdown dropdown-top" *ngIf="isEditingPicture()">
						<div tabindex="0" role="button" class="w-[30px] h-[30px] flex justify-center items-center rounded-full bg-white bg-opacity-25">
							<div class="rounded-full"
								[ngClass]="{
									'bg-red-600': redActive,
									'bg-white': whiteActive,
									'bg-black': blackActive,
									'bg-yellow-200': yellowActive,
									'bg-green-600': greenActive,
									'w-[7px] h-[7px]': smallBrushActive,
									'w-[10px] h-[10px]': mediumBrushActive,
									'w-[15px] h-[15px]': largeBrushActive,

								}"></div>
						</div>
						<ul tabindex="0" class="dropdown-content z-[1] menu bg-base-200 rounded-box space-y-2">
							<div class="rounded-lg cursor-pointer h-8 w-16 flex justify-center items-center hover:bg-base-300" (click)="changeBrushSize(1)" [ngClass]="{'bg-base-100' : smallBrushActive}">
								<button class="bav-action">
									<div class="w-[7px] h-[7px] bg-black rounded-full">
									</div>
								</button>
							</div>
							<div class="rounded-lg cursor-pointer h-8 w-16 flex justify-center items-center hover:bg-base-300" (click)="changeBrushSize(5)" [ngClass]="{'bg-base-100' : mediumBrushActive}">
								<button class="bav-action">
									<div class="w-[10px] h-[10px] bg-black rounded-full">
									</div>
								</button>
							</div>
							<div class="rounded-lg cursor-pointer h-8 w-16 flex justify-center items-center hover:bg-base-300" (click)="changeBrushSize(10)" [ngClass]="{'bg-base-100' : largeBrushActive}">
								<button class="bav-action">
									<div class="w-[15px] h-[15px] bg-black rounded-full">
									</div>
								</button>
							</div>
						</ul>
					</div>
					<div class="h-[30px] w-[1px] bg-white"></div>
					<div class="w-[30px] h-[30px] p-[2px] inline-block rounded-full" [ngClass]="{'border-white border-[2px]': whiteActive}" *ngIf="isEditingPicture()">
						<button class="w-full h-full bg-white rounded-full" (click)="changeBrushColor('#ffffff')">
						</button>
					</div>
					<div class="w-[30px] h-[30px] p-[2px] inline-block rounded-full" [ngClass]="{'border-black border-[2px]': blackActive}" *ngIf="isEditingPicture()">
						<button class="w-full h-full bg-black rounded-full" (click)="changeBrushColor('#000000')">
						</button>
					</div>
					<div class="w-[30px] h-[30px] p-[2px] inline-block rounded-full" [ngClass]="{'border-red-600 border-[2px]': redActive}" *ngIf="isEditingPicture()">
						<button class="w-full h-full bg-red-600 rounded-full" (click)="changeBrushColor('#ff0000')">
						</button>
					</div>
					<div class="w-[30px] h-[30px] p-[2px] inline-block rounded-full" [ngClass]="{'border-green-600 border-[2px]': greenActive}" *ngIf="isEditingPicture()">
						<button class="w-full h-full bg-green-600 rounded-full" (click)="changeBrushColor('#008000')">
						</button>
					</div>
					<div class="w-[30px] h-[30px] p-[2px] inline-block rounded-full" [ngClass]="{'border-yellow-200 border-[2px]': yellowActive}" *ngIf="isEditingPicture()">
						<button class="w-full h-full bg-yellow-200 rounded-full" (click)="changeBrushColor('#ffff00')" *ngIf="isEditingPicture()">
						</button>
					</div>
				</div>
				<div class="flex justify-between px-7 py-5" *ngIf="isEditingPicture()">
					<button (click)="goBackToPrevPage()" class="btn btn-circle btn-link btn-sm">
						<app-svg-icon [src]="exitIcon" stroke="white" strokeWidth="2px" width="32px" height="32px"/>
					</button>
					<button [disabled]="cameraUploadLoading" (click)="saveCanva()" class="btn btn-circle btn-link btn-sm">
						<span *ngIf="cameraUploadLoading" class="loading loading-spinner"></span>
						<app-svg-icon *ngIf="!cameraUploadLoading" [src]="checkIcon" stroke="white" strokeWidth="2px" width="32px" height="32px"/>
					</button>
				</div>
				<!-- <div class="bav-action-separation" *ngIf="isEditingPicture()"></div> -->
				<!-- <div class="bav-action-separation bav-action-separation-redo" *ngIf="isEditingPicture()"></div> -->
			</div>
		</div>
</div>

<!-- <h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
	<li>{{error | json}}</li>
</ul> -->