import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import flatpickr from 'flatpickr';
import { FormControl } from '@angular/forms';
import { icons } from 'src/assets/images/icons';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  public iconPack = icons;

  @Input() control: FormControl = new FormControl(); // Reactive form control
  @Input() class?: string | null = null; // Custom classes for styling
  @Input() label?: string | null = null; // Label text
  @Input() requiredText?: string | null = null; // Validation message
  @Input() placeholder?: string | null = null; // Placeholder text

  @ViewChild('dateInput', { static: true }) dateInput!: ElementRef; // Reference to the input element

  flatpickrInstance: any; // Flatpickr instance

  today: Date = new Date(); // Current date for minDate configuration

  ngOnInit(): void {
    this.initializeFlatpickr();
  }

  initializeFlatpickr(): void {
    this.flatpickrInstance = flatpickr(this.dateInput.nativeElement, {
      dateFormat: 'dd-mm-YYYY', // Format for value binding (e.g., "2024-12-20")
      altInput: true, // Display a human-readable format in the input
      altFormat: 'd/m/Y', // Human-readable format (e.g., "December 20, 2024")
      allowInput: false, // Allow manual input of dates
      minDate: new Date(), // Disable past dates
      defaultDate: this.control.value ? new Date() : undefined,
      disableMobile: true, // Force non-native Flatpickr on mobile devices
      onReady: (selectedDates: Date[], dateStr: string, instance: any) => {
        (instance.altInput as HTMLInputElement).placeholder = this.placeholder || 'Select Date';
      },
      onChange: (selectedDates) => {
        this.control.setValue(selectedDates[0]); // Update reactive form control value
      },
      onClose: () => {
        const control = this.control;
        if (control) {
          control.markAsDirty();
          control.markAsTouched();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy(); // Destroy Flatpickr instance to avoid memory leaks
    }
  }

  onClearDate() {
    this.flatpickrInstance.clear();
    this.flatpickrInstance.open();
  }
}
