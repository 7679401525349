import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private originPage: { [key: string]: any } = {};

  
  setOriginForPage(filterKey: string, state: any) {
    this.originPage[filterKey] = state;
  }

  getOriginForPage(filterKey: string) {
    return this.originPage[filterKey] || null;
  }

  clearOriginForPage(filterKey: string) {
    this.originPage[filterKey] = null;
  }
}
