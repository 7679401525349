<div class="chat chat-start !gap-0">
    <div class="chat-image avatar">
      <div class="w-[50px]">
        <!-- <img class="!object-contain transform -scale-x-100" src="../../../../assets/images/logo/bavox-mascot.svg" /> -->
      </div>
    </div>
    <div class="flex items-end gap-1">
      <div class="chat-bubble !min-h-0 rounded-md before:!content-none" [ngClass]="{'!bg-[#EDF3E6]':!isDeleted(), '!bg-transparent !border-2 !border-[#EDF3E6]': isDeleted()}">
        <div class="flex items-center justify-between gap-2">
          <h1 class="text-[#81BE3F] font-medium text-base text-left" *ngIf="isDeleted()">Notiz gelöscht</h1>
          <h1 class="text-[#81BE3F] font-medium text-base text-left" *ngIf="!isDeleted()">Notiz hinzugefügt</h1>
            <div tabindex="0" *ngIf="!isDeleted()" class="dropdown dropdown-end">
              <div tabindex="0" role="button" class="btn btn-square !rounded-lg bg-white btn-sm -mr-2">
                <app-svg-icon
                  [src]="showMoreIcon"
                  width="24px"
                  height="24px"
                  strokeWidth="2px"
                />
              </div>
              <ul class="dropdown-content z-[1] menu p-2 shadow bg-white rounded-box w-44">
                <li *ngIf="!isDeleted()" (click)="addPicture()">
                  <button class="text-secondary-color-500">
                      <app-svg-icon
                        [src]="cameraIcon"
                        width="24px"
                        height="24px"
                        stroke="black"
                      />
                      Bild hinzufügen
                  </button>
                </li>
                <li *ngIf="!isDeleted()" (click)="deleteNote()">
                  <button class="text-error-color-500 hover:text-error-color-500">
                      <app-svg-icon
                        [src]="trashIcon"
                        [stroke]='"rgb(246 94 94 / var(--tw-text-opacity))"'
                        width="24px"
                        height="24px"
                      />
                      Löschen
                  </button>
                </li>
                <li *ngIf="!isDeleted()">
                  <button class="text-secondary-color-500" (click)="navigateToGallery(message.data.note.id)">
                      <app-svg-icon
                          [src]="imageIcon"
                          [stroke]='"black"'
                          width="24px"
                          height="24px"
                      />
                      Fotos anzeigen
                  </button>
                </li>
                <li *ngIf="!isDeleted()">
                  <button class="text-secondary-color-500" (click)="toggleAddDueModal(message.data.note.id)">
                      <app-svg-icon
                          [src]="calendarIcon"
                          [stroke]='"black"'
                          width="24px"
                          height="24px"
                      />
                      Frist hinzufügen
                  </button>
                </li>
              </ul>
            </div>
        </div>
        <p class="text-left" [class.bav-deleted]="isDeleted()">{{message.data.note.note}}</p>
      </div>
      <div class="chat-footer opacity-50 break-keep">
        <p class="text-nowrap">{{message.date | date: "HH:mm"}}</p>
      </div>
    </div>
</div>


  <dialog id="dueDateModal" class="modal" [ngClass]="{'modal-open': isAddDueDateModalOpen}">
    <form (click)="toggleAddDueModal(taskId)" method="dialog" class="modal-backdrop">
      <button></button>
    </form>
    <div class="modal-box max-w-screen-xs !p-0">
      <div class="flex justify-between px-4 pt-4 pb-3 border-b-[1px] border-white-shades-400">
        <h3 class="font-medium text-base">{{"kanban-general.calendar" | translate}}</h3>
        <form (click)="toggleAddDueModal(taskId)" method="dialog">
          <button class="btn btn-circle btn-link btn-xs absolute right-[16px] top-[16px]">
            <app-svg-icon
              [src]="exitIcon"
              width="24px"
              height="24px"
            />
          </button>
        </form>
      </div>
      <form [formGroup]="dueDateTask" (submit)="onDueDateSubmit()">
        <div class="p-4">
          <app-date-picker
            placeholder="{{'task-details.due-date' | translate}}"
            [control]="this.dueDateTask.controls['deadlineDate']"
          />
        </div>
        <div class="flex justify-end px-4 pb-4 gap-2">
          <form (click)="toggleAddDueModal(taskId)" method="dialog">
            <button class="btn btn-link btn-link-neutral btn-md w-[134px]">{{"kanban-general.cancel" | translate}}</button>
          </form>
          <button
            type="submit"
            [disabled]="!dueDateTask.valid || isLoading"
            class=" btn btn-primary btn-md w-[134px]"
          >
            <span *ngIf="isLoading" class="loading loading-spinner"></span>
            <span *ngIf="!isLoading">
              {{"task-details.add-due-date-confirm" | translate}}
            </span>
          </button>
        </div>
      </form>
    </div>
  </dialog>