import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private envUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  listAllProjects(token:string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/projects`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'

      },
    })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  listLimitedProjects(token:string | null, limitNumber: number | null): Observable<any> {
    return this.http.get(`${this.envUrl}/projects?limit=${limitNumber}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      },
    })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  getProjectById(token:string | null, projectId: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/projects/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  createProject(token:string | null, inputValues: any): Observable<any> {
    return this.http
    .post(`${this.envUrl}/projects/`, inputValues, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      },
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }

  updateProject(token:string | null, inputValues: any, projectId: string | null): Observable<any> {
    return this.http
      .put(`${this.envUrl}/projects/${projectId}`, inputValues, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  deleteProject(token:string | null, projectId: string): Observable<any> {
    return this.http
    .delete(`${this.envUrl}/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      },
    })
    .pipe(
      map((res: any) => res),
      catchError((error: any): Observable<any> => error)
    );
  }

  listProjectDetails(token:string | null, projectId: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/projects/${projectId}/details`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  getSharedLinkById(token: string | null, sharedLinkId: string | null): Observable<any> {
    return this.http
    .get(`${this.envUrl}/projects/shared/${sharedLinkId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      },
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }

  getSharedProjectJournalById(token: string | null, sharedLinkId: string | null, journalId: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/journals/shared/${sharedLinkId}/${journalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  getSharedProjectById(token: string | null, sharedLinkId: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/projects/shared/${sharedLinkId}/information`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  shareProject(token:string | null, data:any, projectId: string | null): Observable<any> { 
    return this.http
      .post(`${this.envUrl}/projects/shared/${projectId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  listSharedProjectJournals(token: string | null, sharedLinkId: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/journals/shared/${sharedLinkId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  deleteSharedProjectJournal(token: string | null, sharedLinkId: string | null, journalId: string | null): Observable<any> {
    return this.http
      .delete(`${this.envUrl}/journals/shared/${sharedLinkId}/${journalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => error)
      )
  }

  updateSharedProjectJournal(token: string | null, sharedLinkId: string | null, journalId: string | null, data: any): Observable<any> {
    return this.http
      .put(`${this.envUrl}/journals/shared/${sharedLinkId}/${journalId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  createSharedProjectJournal(token: string | null, sharedLinkId: string | null, body: any): Observable<any> {
    return this.http
      .post(`${this.envUrl}/journals/shared/${sharedLinkId}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res:any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  listSharedJournalsTaskStaus(token: string | null, sharedLinkId: string | null): Observable<any> {
    let headers = new HttpHeaders({
      'ngrok-skip-browser-warning': '555'
    });

    // Add Authorization header only if token is not null or undefined
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    return this.http
    .get(`${this.envUrl}/projects/shared/${sharedLinkId}/journals/status`, {
      headers
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }

  resendEmailToExternalUser(token: string | null, sharedLinkId: string | null): Observable<any> {
    return this.http
      .post(`${this.envUrl}/projects/shared/${sharedLinkId}/resend`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => throwError(error))
      );
  }

  resendDesclinedRequest(token: string | null, sharedLinkId: string | null): Observable<any> {
    return this.http
      .post(`${this.envUrl}/projects/shared/${sharedLinkId}/resend-declined`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => throwError(error))
      );
  }

  listSharedDocumentsByProject(sharedLinkId: string | null): Observable<any> {
    return this.http
    .get(`${this.envUrl}/projects/shared/${sharedLinkId}/documents`, {
      headers: {
        'ngrok-skip-browser-warning': '555'
      },
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }

  deleteSharedLink(token:string | null, sharedLinkId: string | null): Observable<any> {
    return this.http
      .delete(`${this.envUrl}/projects/shared/${sharedLinkId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => error)
      )
  }

  updateSharedLink(token:string | null, sharedLinkId: string | null, data: any): Observable<any> {
    return this.http
      .put(`${this.envUrl}/projects/shared/${sharedLinkId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  connectSharedProject(token: string | null, sharedLinkId: any): Observable<any> {
    return this.http
      .post(`${this.envUrl}/projects/shared/connect/${sharedLinkId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => throwError(error))
      );
  }

  disconnectSharedProject(token:string | null, sharedLinkId: string | null): Observable<any> {
    return this.http
      .delete(`${this.envUrl}/projects/shared/disconnect/${sharedLinkId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        }
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => error)
      )
  }

  listJournalsTaskStatus(token: string | null, projectId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/projects/${projectId}/journals/status`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    }).pipe(
      map((res:any) => res.data),
      catchError((error: any): Observable<any> => error)
    )
  }

  listProjectJournals(token:string | null, projectId: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/projects/${projectId}/journals`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  getJournalById(token:string | null, journalId: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/journals/${journalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res:any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  createJournal(token:string | null, body:any): Observable<any> {
    return this.http
      .post(`${this.envUrl}/journals`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res:any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  updateJournal(token:string | null, journalId: string | null, inputValues: any): Observable<any> {
    return this.http
      .put(`${this.envUrl}/journals/${journalId}`, inputValues, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  deleteJournal(token:string | null, journalId: string): Observable<any> {
    return this.http
      .delete(`${this.envUrl}/journals/${journalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        }
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => error)
      )
  }

  listDocumentEntities(token: string | null, projectId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/projects/${projectId}/documents/entities`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      },
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    )
  }

  listSharedDocumentEntites(sharedLinkId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/projects/shared/${sharedLinkId}/documents/entities`, {
      headers: {
        'ngrok-skip-browser-warning': '555'
      },
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    )
  }

  listDocumentsByProject(token: string | null, projectId: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/projects/${projectId}/documents`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res:any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  getDocumentByKey(token: string | null, projectId: string | null, documentKey: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/projects/${projectId}/documents/${documentKey}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res:any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  uploadDocument(token: string | null, projectId: string | null, formData: any): Observable<any> {
    return this.http
      .post(`${this.envUrl}/projects/${projectId}/documents`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  deleteDocument(token: string | null, projectId: string | null, documentKey: string | null): Observable<any> {
    return this.http
      .delete(`${this.envUrl}/projects/${projectId}/documents/${documentKey}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        }
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => error)
      )
  }

  updateDocument(token: string | null, projectId: string | null, documentKey: string | null, formData: any): Observable<any> {
    return this.http
      .put(`${this.envUrl}/projects/${projectId}/documents/${documentKey}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  updateDocumentEntities(token: string | null, projectId: string | null, data: any): Observable<any> {
    return this.http
    .put(`${this.envUrl}/projects/${projectId}/documents`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      },
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }

  shareNestedProject(token: string | null, sharedLinkId: string | null, data: any): Observable<any> {
    return this.http
      .post(`${this.envUrl}/projects/shared/${sharedLinkId}/nested`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  listNestedProjectMembers(token: string | null, sharedLinkId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/projects/shared/${sharedLinkId}/nested`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    }).pipe(
      map((res:any) => res.data),
      catchError((error: any): Observable<any> => error)
    )
  }

  listProjectMembers(token: string | null, projectId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/projects/${projectId}/shared/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    }).pipe(
      map((res:any) => res.data),
      catchError((error: any): Observable<any> => error)
    )
  }

  getProjectMemberById(token: string | null, memberId: string | null): Observable<any> {
    return this.http
      .get(`${this.envUrl}/projects/shared/${memberId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res:any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  updateNestedProjectMember(token: string | null, sharedLinkId: string | null, memberId: string | null, formData: any): Observable<any> {
    return this.http
      .put(`${this.envUrl}/projects/shared/${sharedLinkId}/nested/${memberId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  updateProjectMember(token: string | null, memberId: string | null, formData: any): Observable<any> {
    return this.http
      .put(`${this.envUrl}/projects/shared/${memberId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  deleteNestedProjectMember(token: string | null,sharedLinkId: string | null, memberId: string | null): Observable<any> {
    return this.http
      .delete(`${this.envUrl}/projects/shared/${sharedLinkId}/nested/${memberId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => error)
      );
  }

  deleteProjectMember(token: string | null, memberId: string | null): Observable<any> {
    return this.http
      .delete(`${this.envUrl}/projects/shared/${memberId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any): Observable<any> => error)
      );
  }

  checkUserStatus(token: string | null, email?: string | null | undefined, projectId?: string | null): Observable<any> {
    let params;
    if( email && projectId ) {
      params = {
        email,
        projectId
      }
    } else if( email ) {
      params = {
        email,
      }
    }

    return this.http.get(`${this.envUrl}/users/status`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'

      },
    })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

}
